import configuration from "configuration";
const API_BASE_URL = configuration.apiUrl;
export const API_ROUTES = {
    auth: {
        login: {
            endpoint: 'auth/signIn',
            key: 'login'
        },
        logout: '/api/auth/logout',
        fetchTokens: 'auth/fetchTokens',
    },
    productCategory: {
        get: {
            key: "getProductCategories",
            endpoint: 'productCategory'
        },
        create: {
            key: "createVendorProducts",
            endpoint: 'productCategory'
        },
        update: {
            key: "updateVendorProducts",
            endpoint: 'productCategory'
        },
        delete: {
            key: "deleteVendorProducts",
            endpoint: 'productCategory'
        },
    },
    products: {
        get: {
            key: "getVendorProducts",
            endpoint: 'vendor/getProducts'
        },
        create: {
            key: "createVendorProducts",
            endpoint: 'product'
        },
        update: {
            key: "updateVendorProducts",
            endpoint: 'product'
        },
        delete: {
            key: "deleteVendorProducts",
            endpoint: 'product'
        },
        popular: {
            key: "GetPopularProducts",
            endpoint: "analytics/popularProducts"
        },
        lowStock: {
            key: "GetLowStockProducts",
            endpoint: "analytics/lowStock"
        },
    },
    account: {
        get: {
            key: "getAccount",
            endpoint: "vendor/get"
        },
        update: {
            key: "updateAccount",
            endpoint: "vendor/account"
        },
    },
    serverEvents: {
        subscribe: `${API_BASE_URL}/serverEvent/subscribe`
    },
    transactions: {
        get: {
            endpoint: "transactions",
            key: "getTransactions"
        },
        getUndelivered: {
            endpoint: "transactions?productsOnly=true&onlyUndelivered=true",
            key: "getUndeliveredOrders"
        },
        getDetailed: {
            endpoint: "transactions",
            key: "getDetailedTransactionInformation"
        },
        markCompleted: {
            endpoint: "vendor/markOrderComplete",
            key: "markOrderCompleted"
        },
    }
}
