import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { PublicElement, ProtectedElement } from "components/Element";

const HomePage = lazy(() => import("pages/HomePage"));
const Login = lazy(() => import("pages/Login"));
const Account = lazy(() => import("pages/Account"));

const Transactions = lazy(() => import("pages/Transactions"));
const Products = lazy(() => import("pages/Products"));

const FourOhFour = lazy(() => import("pages/Error/FourOhFour"));

const Router = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/login" element={<PublicElement component={Login} />} />

      {/* Protected Routes */}
      <Route path="/" element={<ProtectedElement component={HomePage} />} />
      <Route path="/account" element={<ProtectedElement component={Account} />} />

      <Route
        path="/transactions"
        element={<ProtectedElement component={Transactions} hideContainer />}
      />
      <Route path="/products" element={<ProtectedElement component={Products} />} />
      {/* 404 page if none of the routes match */}
      <Route path="*" element={<FourOhFour />} />
    </Routes>
  );
};

export default Router;
